import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "react-bootstrap";

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const HouModal = ({ open, onClose, onConfirm }: Props) => {
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="sm"
        sx={{ ".MuiDialog-paperWidthSm ": { "max-width": "520px" } }}
      >
        <Paper>
          <DialogTitle
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h6"
              component="div"
              style={{ fontWeight: "bold" }}
            >
              Confirm Lease Generation - HOU Account
            </Typography>
            <Button
              onClick={onClose}
              variant="text"
              style={{ position: "absolute", right: 8, fontSize: "16px" }}
            >
              Close
              <CloseIcon />
            </Button>
          </DialogTitle>
          <DialogContent style={{ padding: "20px 24px 0px" }}>
            <Typography
              variant="body1"
              component="div"
              style={{ fontWeight: "bold" }}
            >
              Before you proceed with generating the lease, confirm that you
              have taken the following actions:
            </Typography>
            <br />
            <ol>
              <li>
                Received approval of the rate amount from the Housing Authority
              </li>
              <br />
              <li>
                Updated the base rent and HOU charge in MRI to match that
                approved by the Housing Authority
              </li>
              <br />
              <li>
                Ensure the lease term is approved by the Housing Authority
              </li>
            </ol>
          </DialogContent>
          <DialogActions
            style={{ justifyContent: "right", paddingBottom: "8px" }}
          >
            <div>
              <Button onClick={() => onClose()} variant="outline-secondary">
                Cancel
              </Button>
              <Button
                onClick={() => onConfirm()}
                data-testid="submit-button"
                className="btn-primary"
              >
                Confirm & Generate
              </Button>
            </div>
          </DialogActions>
        </Paper>
      </Dialog>
    </>
  );
};

export default HouModal;
