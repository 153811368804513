import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  Paper,
  TextField,
} from "@mui/material";
import { Button } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { ReduxApplication } from "src/types/Application";
import { useAppSelector } from "src/hooks/redux";

interface Props {
  open: boolean;
  onClose: () => void;
  selectedApplication: ReduxApplication | undefined;
  onSubmit: (string) => void;
  previousLegalRent: string;
  setPreviousLegalRent: (value: string) => void;
}

const RentStabilizationModal = ({
  open,
  onClose,
  selectedApplication,
  onSubmit,
  previousLegalRent,
  setPreviousLegalRent,
}: Props) => {
  const [disableSendButton, setDisableSendButton] = useState(true);
  const notification = useAppSelector(
    (state) => state.applications.notification
  );
  useEffect(() => {
    setDisableSendButton(false);
    if (
      notification &&
      notification.message &&
      notification.type === "INFO" &&
      notification.message.endsWith(
        "Lease has been generated and successfully sent out"
      )
    ) {
      onClose();
    }
  }, [notification]);

  const isValidInput = (value) => {
    const regex = /^(?!.*\..*\.)[0-9]*(\.[0-9]{0,2})?$/;
    return regex.test(value);
  };

  const handlePreviousLegalRentChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (isValidInput(event.target.value)) {
      setDisableSendButton(false);
      setPreviousLegalRent(event.target.value);
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="xs">
        <Paper>
          <DialogTitle> Rent Stabilization Rider - NYC</DialogTitle>
          <DialogContent>
            Please enter previous legal regulated rent value for apartment{" "}
            {selectedApplication?.completeUnitId}
            <Grid
              container
              rowSpacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item md={12} xs={12} mt={1}>
                <TextField
                  label="Enter amount"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    inputMode: "numeric",
                  }}
                  onChange={handlePreviousLegalRentChange}
                  value={previousLegalRent}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ justifyContent: "right" }}>
            <div>
              <Button onClick={() => onClose()} variant="outline-secondary">
                Cancel
              </Button>
              <Button
                onClick={() => {
                  setPreviousLegalRent("");
                  onSubmit(previousLegalRent);
                }}
                data-testid="generate-lease-button"
                className="btn-primary"
                disabled={disableSendButton}
              >
                Submit
              </Button>
            </div>
          </DialogActions>
        </Paper>
      </Dialog>
    </>
  );
};

export default RentStabilizationModal;
